<template>
    <c-panel v-if="request?.data">
        <c-grid class="spacing-xl-all widths-50-all widths-100-md">
            <c-grid-item>
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <c-textbox
                            label="Name"
                            autocomplete="name"
                            v-model="request.data.name"
                            :disabled="!canEdit()"
                        />
                    </c-grid-item>
                    <c-grid-item>
                        <c-textbox
                            label="Email Address"
                            autocomplete="email"
                            v-model="request.data.email"
                            :disabled="!canEdit()"
                        />
                    </c-grid-item>
                    <c-grid-item>
                        <c-select
                            label="Requested Service"
                            :options="[
                                'Art Digitization',
                                'Image Editing',
                                'Image Enlargement',
                                'Photo Restoration',
                                'Canvas Wrapping',
                                'ICC Profile Creation',
                                'Other / Custom',
                            ]"
                            v-model="request.data.service"
                            :disabled="!canEdit()"
                        />
                    </c-grid-item>
                    <c-grid-item>
                        <c-textarea label="Message" lines="8" v-model="request.data.message" :disabled="!canEdit()" />
                    </c-grid-item>
                    <c-grid-item v-if="request.data.status == 'Pending'">
                        <c-grid>
                            <c-grid-item class="width-grow-all flex f-align-center text-sm text-light">
                                Expect a response within 24 hours
                            </c-grid-item>
                            <c-grid-item>
                                <c-button @click="submitRequest()" :disabled="!canSubmit()">Submit Request</c-button>
                            </c-grid-item>
                        </c-grid>
                    </c-grid-item>
                    <c-grid-item v-else-if="request.data.status == 'Editing'">
                        <c-grid class="widths-100-all">
                            <c-grid-item class="flex f-justify-end">
                                <c-button-group>
                                    <c-button @click="submitRequest()">Save Changes</c-button>
                                    <c-button class="light" @click="cancelEdit()">Cancel</c-button>
                                </c-button-group>
                            </c-grid-item>
                        </c-grid>
                    </c-grid-item>
                    <c-grid-item v-else-if="request.data.status == 'New'">
                        <c-grid class="widths-100-all">
                            <c-grid-item class="flex f-justify-end">
                                <c-button-group>
                                    <c-button @click="editRequest()">
                                        <template #icon>
                                            <i class="fas fa-pen"></i>
                                        </template>
                                        Edit Request
                                    </c-button>
                                    <c-button @click="cancelRequest()" class="red">
                                        <template #icon>
                                            <i class="fas fa-times"></i>
                                        </template>
                                        Cancel Request
                                    </c-button>
                                </c-button-group>
                            </c-grid-item>
                            <c-grid-item class="flex f-align-center text-green text-center mt-3">
                                <c-box class="confirmation">
                                    Your request was successfully sent! You will receive an email response within 24
                                    hours.
                                </c-box>
                            </c-grid-item>
                        </c-grid>
                    </c-grid-item>
                    <c-grid-item v-else-if="request.data.status == 'Canceled'">
                        <c-grid class="widths-100-all">
                            <c-grid-item class="flex f-justify-center text-green text-center mt-3">
                                <c-box class="cancellation">
                                    <p>Your request has been canceled.</p>
                                    <p class="mt-1">
                                        <router-link :to="'/request'">Click here</router-link> to create a new request.
                                    </p>
                                </c-box>
                            </c-grid-item>
                        </c-grid>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
            <c-grid-item v-if="request?.data?.images">
                <h2>Image Attachments (Optional)</h2>
                <c-grid class="widths-100-all">
                    <c-grid-item v-for="(image, i) in request.data.images" :key="i">
                        <c-card>
                            <template #image>
                                <c-image :zoom="true" :image="image" />
                            </template>
                            <div style="position: absolute; right: 10px; top: 10px" v-if="canEdit()">
                                <c-button class="red" @click="deleteImage(i)">
                                    <template #icon>
                                        <i class="fas fa-trash"></i>
                                    </template>
                                </c-button>
                            </div>
                        </c-card>
                    </c-grid-item>
                    <c-grid-item v-if="!canEdit() && request.data.images.length == 0">
                        <p>None</p>
                    </c-grid-item>
                    <c-grid-item v-if="canEdit()">
                        <c-image-picker
                            :upload="uploadImage"
                            v-model="image"
                            :resetAfterApply="true"
                            @apply="insertImage()"
                        />
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, ref } from "vue";
import { useRoute } from "vue-router";
import { Database, Storage } from "vuebase";
import { ServiceRequest } from "../main";
import { ImageData } from "coho-ui";
import server from "@/server";

export default {
    name: "Product",
    setup() {
        const requestId = useRoute().params.id as string;
        const database = inject(Database.InjectionKey);
        const request = requestId
            ? database?.collection<ServiceRequest>("requests").document(requestId)
            : database?.collection<ServiceRequest>("requests").new(undefined, {
                  name: "",
                  email: "",
                  service: "Other / Custom",
                  message: "",
                  images: [],
                  status: "Pending",
                  dateCreated:
                      Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp,
              });

        const image = ref<ImageData>();

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "requests/" + (request?.id ?? "unknown"),
                progressCallback,
                Storage.CachePolicy.Day,
                { width: 1800, height: 1800 },
                100,
            );
        }

        function insertImage(): void {
            if (image.value) {
                request?.data?.images.push(image.value);
            }
        }

        function deleteImage(index: number): void {
            request?.data?.images.splice(index, 1);
        }

        function submitRequest(): Promise<void> {
            if (request?.data) {
                request.data.status = "New";
                insertImage();
                return request.save().then();
            }

            return Promise.reject();
        }

        function editRequest(): Promise<void> {
            if (request?.data) {
                request.data.status = "Editing";
                return request.save().then();
            }

            return Promise.reject();
        }

        function cancelRequest(): Promise<void> {
            if (request?.data) {
                const result = window.confirm("Are you sure you want to cancel your request?");

                if (result) {
                    request.data.status = "Canceled";
                    return request.save().then();
                }
            }
            return Promise.reject();
        }

        function cancelEdit(): Promise<void> {
            if (request?.data) {
                request.discard();
                request.data.status = "New";
                return request.save().then();
            }
            return Promise.reject();
        }

        function canEdit(): boolean {
            return request?.data?.status == "Pending" || request?.data?.status == "Editing";
        }

        function canSubmit(): boolean {
            return request?.data?.email != "" && request?.data?.name != "" && request?.data?.message != "";
        }

        return {
            request,
            status,
            image,
            uploadImage,
            insertImage,
            deleteImage,
            submitRequest,
            editRequest,
            cancelRequest,
            cancelEdit,
            canEdit,
            canSubmit,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~coho-ui/src/styles/variables.scss";

.confirmation {
    background-color: $color-green;
    color: $color-white;
    padding: $padding-lg;
    animation: request-confirmation-entrance 300ms ease-in-out;
}

.cancellation {
    background-color: $color-red;
    color: $color-white;
    padding: $padding-lg;
    animation: request-confirmation-entrance 300ms ease-in-out;

    a {
        color: inherit;
        text-decoration: underline;
        transition: all 200ms ease-in-out;

        &:hover {
            opacity: 0.7;
        }
    }
}

@keyframes request-confirmation-entrance {
    from {
        transform: scale(0.9);
        opacity: 0.7;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
