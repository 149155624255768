
import { inject, ref } from "vue";
import { useRoute } from "vue-router";
import { Database, Storage } from "vuebase";
import { ServiceRequest } from "../main";
import { ImageData } from "coho-ui";
import server from "@/server";

export default {
    name: "Product",
    setup() {
        const requestId = useRoute().params.id as string;
        const database = inject(Database.InjectionKey);
        const request = requestId
            ? database?.collection<ServiceRequest>("requests").document(requestId)
            : database?.collection<ServiceRequest>("requests").new(undefined, {
                  name: "",
                  email: "",
                  service: "Other / Custom",
                  message: "",
                  images: [],
                  status: "Pending",
                  dateCreated:
                      Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp,
              });

        const image = ref<ImageData>();

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "requests/" + (request?.id ?? "unknown"),
                progressCallback,
                Storage.CachePolicy.Day,
                { width: 1800, height: 1800 },
                100,
            );
        }

        function insertImage(): void {
            if (image.value) {
                request?.data?.images.push(image.value);
            }
        }

        function deleteImage(index: number): void {
            request?.data?.images.splice(index, 1);
        }

        function submitRequest(): Promise<void> {
            if (request?.data) {
                request.data.status = "New";
                insertImage();
                return request.save().then();
            }

            return Promise.reject();
        }

        function editRequest(): Promise<void> {
            if (request?.data) {
                request.data.status = "Editing";
                return request.save().then();
            }

            return Promise.reject();
        }

        function cancelRequest(): Promise<void> {
            if (request?.data) {
                const result = window.confirm("Are you sure you want to cancel your request?");

                if (result) {
                    request.data.status = "Canceled";
                    return request.save().then();
                }
            }
            return Promise.reject();
        }

        function cancelEdit(): Promise<void> {
            if (request?.data) {
                request.discard();
                request.data.status = "New";
                return request.save().then();
            }
            return Promise.reject();
        }

        function canEdit(): boolean {
            return request?.data?.status == "Pending" || request?.data?.status == "Editing";
        }

        function canSubmit(): boolean {
            return request?.data?.email != "" && request?.data?.name != "" && request?.data?.message != "";
        }

        return {
            request,
            status,
            image,
            uploadImage,
            insertImage,
            deleteImage,
            submitRequest,
            editRequest,
            cancelRequest,
            cancelEdit,
            canEdit,
            canSubmit,
        };
    },
};
